@font-face {
  font-family: 'Helios Extended';
  src: url('./assets/fonts/heliosext.otf') format('opentype');
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background-color: #F2EFEA; */
  background-color: #fff;
  scrollbar-width: 0;
  width: 100%;
  height: 100%;
}

#root {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

input {
  width: 100px;
  border: none;
  outline: none;
  font-size: 22px;
  background: transparent;
  font-family: 'Helios Extended';
}
